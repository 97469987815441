import { App, Code, Database, Development, Lamp, ManageDevelopment, Shopify, Truck, BrittniLando, RochelFoose, Project1, Project2, Project3, Project4 } from "../assets/images";

const menu = [
    { name: "Pricing", href: "/#plans" },
    { name: "Our Services", href: "/#services", },
    { name: "Why Choose Us", href: "/#why-choose-us", },
    { name: "Our Project", href: "/#projects", },
    { name: "FAQS", href: "/#frequently-asked-questions", },
];

const membershipPlans = [
    {
        id: 1,
        title: "Basic Membership",
        description: "For small businesses just getting started",
        price: "$399",
        discountPrice:'$372',
        pricing_type: "One time",
        plan: "Basic",
        tag: "",
        disccount:'(Get 10% Discount)',
        features: [
            "Get e-com Website (Full Store setup)",
            "Website into shopity basic theme",
            "Mobile responsive & fast load speed",
            "20 Product listing",
            "10 Free product reviews",
            "3 Days free facebook marketing",
            "Free domain setup",
            "2 Revisions (after 2 change other changes will be paid",
            "12/7 Support",
        ],
    },
    {
        id: 2,
        title: "Premium Membership",
        description: "For small businesses just getting started.",
        price: "$599",
        discountPrice:'$540',
        pricing_type: "One time",
        plan: "Premium",
        tag: "Popular",
        disccount:'(Get 12% Discount)',
        features: [
            "Get e-com Website (Full store setup)",
            "Website into Shopify with Premium theme worth 70$ to 100$",
            "Mobile responsive & fast load speed",
            "Store graphics & logo",
            "20 Free Product Reviews",
            "5 Days free facebook marketing",
            "Free domain setup",
            "Payment method integration",
            "4 Revisions (after 4 change other changes will be paid",
            "24/7 Support",
            "WhatsApp button",
            "Basic SEO"
        ],
    },
    {
        id: 3,
        title: "Diamond Membership",
        description: "For small businesses just getting started.",
        price: "$799",
        discountPrice:'$704',
        pricing_type: "One time",
        plan: "Diamond",
        tag: "",
        disccount:'(Get 15% Discount)',
        features: [
           "Get e-com Website (Full store setup)",
            "Website into Shopify with Premium theme worth 100$ to 150$",
            "Mobile responsive & fast load speed",
            "Store graphics & logo",
            "Unlimited product listing",
            "40 Free Product Reviews",
            "7 Days free facebook marketing",
            "Free domain setup",
            "Payment method integration",
            "6 Revisions (after 8 change other changes will be paid",
            "24/7 Support",
            "WhatsApp button",
            "Basic SEO"
        ],
    },
];

const services = [
    {
        title: "Shopify Store Setup & Configuration",
        description: "Smooth operation, eye-catching design, and easy navigation are all components of the ideal store layout. Make sure there are obvious calls-to-action, quick loading times, secure payment methods, and mobile responsiveness. Set up shipping choices, tax rates, and inventory management. Use user-friendly features like search bars, filters, and customer support tools, and incorporate analytics for insights.",
        icon: Shopify
    },
    {
        title: "Custom Shopify Theme Design & Development",
        description: "The goal of custom Shopify theme design and development is to produce distinctive user experiences with specialized designs that capture the essence of your company. Skilled developers guarantee mobile optimization, quick loading times, and seamless operation. responsive, eye-catching design and easy navigation are intended to improve the online purchasing experience.",
        icon: Development
    },
    {
        title: "Shopify App Integration",
        description: "Your online store may be easily connected to strong tools to improve consumer experiences, automate processes, and increase functionality through a Shopify app integration. These interfaces, which range from payment gateways and inventory management to marketing automation and analytics, simplify processes, increase productivity, and facilitate the smooth expansion of your eCommerce company. Use specialized app solutions to propel growth!",
        icon: App
    },
    {
        title: "Migration to Shopify",
        description: "Moving your whole online store including its merchandise, client information, orders, and design components to Shopify robust platform is known as migration to Shopify. For expanding companies looking for a dependable and adaptable solution, this method guarantees a smooth transition with expanded functionality, increased scalability, and strong tools to maximize your eCommerce experience.",
        icon: Database
    },
    {
        title: "Shopify Plus Development",
        description: "Advanced solutions designed for high-growth companies are provided by Shopify Plus development. It makes custom integrations, smooth scalability, and improved performance possible. Shopify Plus gives organizations the tools they need to improve customer experiences, expedite processes, and increase revenues with capabilities like headless commerce, automation, and multi-channel selling. Ideal for businesses looking for reliable and adaptable online storefronts.",
        icon: ManageDevelopment
    }
];

const whyChooseUs = [
    {
        icon: Code,
        title: "Expert Shopify Development",
        description: "Using Shopify powerful platform, Shopify developers create, modify, and oversee eCommerce stores. Skilled Shopify developers can guarantee flawless execution whether you're trying to add additional functionality, improve an existing store, or build a new one.",
    },
    {
        icon: Truck,
        title: "Fast Delivery",
        description: "Ensure customer pleasure by fast delivery! Provide faster shipping alternatives, real-time tracking, and a simple checkout process to improve the purchasing experience. Build trust and increase sales on your Shopify business.",
    },
    {
        icon: Lamp,
        title: "Tailored Solutions",
        description: "Tailored Solutions provides professional Shopify website development and design, as well as tailored eCommerce solutions to match the needs of your business. Improve your web store with stylish layouts and flawless user interfaces.",
    },
];

const projects = [
    {
        img: Project1,
        name: "Home Decoration Store",
        location: "United Kingdom, UK"
    },
    {
        img: Project2,
        name: "Mobile Accessories Store",
        location: "United Kingdom, UK"
    },
    // {
    //     img: Project3,
    //     name: "Gift Basket Store",
    //     location: "India"
    // },
    // {
    //     img: Project4,
    //     name: "Gift Basket Store",
    //     location: "New York, USA"
    // },
]

const clientsTestimonials = [
    {
        rating: 5,
        description: "SellFrom Web designed my Shopify home decoration website brilliantly! Their expertise, creativity, and attention to detail exceeded my expectations. Highly recommend them for exceptional website development. Truly a fantastic experience working with them.",
        name: "Rahul Vasoya",
        designation: "Home Decoration",
        img: BrittniLando,
    },
    {
        rating: 5,
        description: "SellFrom Web did an amazing job on my Shopify website for mobile covers, glass, and watch belts. Their expertise, sleek design, and attention to detail exceeded my expectations. Highly recommend their services for exceptional results!",
        name: "Kashyup Bhuva",
        designation: "Infinityve",
        img: RochelFoose,
    },
    // {
    //     rating: 5,
    //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    //     name: "Rochel Foose",
    //     designation: "Hotel Management",
    //     img: RochelFoose,
    // }
];

const faqs = [
    {
        id: 1,
        question: "Do I need technical skills to use Shopify?",
        answer: "No, Shopify is intended to be user-friendly, with drag-and-drop capabilities. Building a basic internet store does not require coding knowledge, although you can tweak it with some coding if you like.",
    },
    {
        id: 2,
        question: "Do you provide ongoing support after launch?",
        answer: "Yes, I can provide continuing assistance following the launch! If you require assistance with troubleshooting, optimization, or new feature suggestions, please contact us at any time. I'm available to assist with both technical and non-technical difficulties.",
    },
    {
        id: 3,
        question: "What payment options does Shopify support?",
        answer: "Shopify Payments, PayPal, Stripe, and other payment methods are all supported by the platform. You can also use third-party gateways if necessary.",
    },
    {
        id: 4,
        question: "Does Shopify offer mobile compatibility?",
        answer: "Yes, Shopify themes are responsive, meaning they are optimized for mobile devices. You can also manage your store using the Shopify mobile app.",
    },
    {
        id: 5,
        question: "Can I sell physical and digital products on Shopify?",
        answer: "Yes, you can sell both physical things (via inventory management) and digital products (such as eBooks or downloaded files).",
    },
    {
        id: 6,
        question: "How can I market my Shopify store?",
        answer: "Shopify offers marketing tools like SEO, email marketing, social network connections, and even paid ad campaigns to help you increase visitors to your business.",
    },
    {
        id: 7,
        question: "What does Shopify development include?",
        answer: "Shopify development includes building and configuring e-commerce websites on the Shopify platform. It includes designing themes, creating custom features with Liquid (Shopify's templating language), integrating third-party apps, configuring payment methods, optimizing shop performance, and providing flawless user experiences for both customers and merchants.",
    },
];

const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. Swaziland)",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (North)",
    "Korea (South)",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]


export { menu, membershipPlans, services, whyChooseUs, projects, clientsTestimonials, faqs, countries };