import React, { useEffect, useState } from 'react';
import Style from './style.module.scss';
import { Close, Logo, Menu } from '../../assets/images';
import { menu } from '../../data';

export default function Header() {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        function checkScreenSize() {
            setIsSmallScreen(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setIsMenuVisible(true);
            }else {
                setIsMenuVisible(false);
            }
        };

        window.addEventListener("resize", checkScreenSize);
        checkScreenSize();

        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    useEffect(() => {
        if (isSmallScreen && isMenuVisible) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      }, [isMenuVisible]);

    function toggleMenu() {
        if (isMenuVisible) {
            setIsAnimating(true);
        } else {
            setIsMenuVisible(true);
        }
    }

    function animationEnd() {
        if (isAnimating) {
            setIsAnimating(false);
            setIsMenuVisible(false); // Fully hide menu after animation ends
        }
    }

    function getInTouch() {
        window.location.replace("/get-in-touch");
    }

    return (
        <header className={`container ${Style.header}`} id='header'>
            <nav>
                <div className={Style.logo}>
                    <a href={void (0)} className={Style.menuBtn} onClick={toggleMenu}>
                        {isMenuVisible ? <img src={Close} alt="Close" title='Close' /> : <img src={Menu} alt="Menu" title='Menu' />}
                    </a>
                    <a href="/"><img src={Logo} alt="sellfromweb" title='sellfromweb' /></a>
                </div>
                {menu && menu.length > 0 && (isMenuVisible || isAnimating) &&
                    <div className={`${Style.menu} ${isAnimating ? Style.hide : Style.show}`} onAnimationEnd={animationEnd}>
                        <ul>
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <a href={item.href}>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                <div className={Style.getInTouch} onClick={getInTouch}>
                    <button className={Style.getInTouchBtn}>Get In Touch</button>
                </div>
            </nav>
        </header>
    )
}