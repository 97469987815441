import { useState, useRef, useEffect, use } from "react";
import { countries } from "../../data";
import Style from "./style.module.scss";
import config from "../../config/index.json";

function GetInTouch() {
    let params = new URLSearchParams(window.location.search);
    let formRef = useRef(null);

    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [contactNumber, setContactNumber] = useState("");
    let [country, setCountry] = useState("");
    let [description, setDescription] = useState("");
    let [plan, setPlan] = useState("");

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    let [errorFields, setErrorFields] = useState({});
    let [loader, setLoader] = useState(false);

    useEffect(() => {
        plan = params.get("plan") ? params.get("plan") : '';
        setPlan(plan);
        if (window.location.search) {
            const cleanUrl = window.location.pathname;
            window.history.replaceState({}, document.title, cleanUrl);
        }
    }, []);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.addEventListener("submit", (e) => {
                e.preventDefault();
                submitForm();
            });
            return () => {
                formRef.current.removeEventListener("submit", (e) => {
                    e.preventDefault();
                    submitForm();
                });
            }
        }
    }, [formRef]);

    function validateFormData() {
        let errors = {};

        if (!name.trim()) errors.name = "Name is required.";

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) errors.email = "Email is required."
        else if (!emailRegex.test(email)) errors.email = "Invalid email format.";

        if (!country.trim()) errors.country = "Country is required.";

        if (!plan.trim()) errors.plan = "Plan selection is required.";

        setErrorFields(errors);

        // Add error class from elements
        Object.keys(errors).forEach(field => {
            const element = document.querySelector(`#${field}`);
            if (element) element.classList.add(Style.error);
        });

        // Remove error class from elements
        ["name", "email", "country", "plan"].forEach(field => {
            if (!errors[field]) {
                const element = document.querySelector(`#${field}`);
                if (element) element.classList.remove(Style.error);
            }
        });

        if (Object.keys(errors).length > 0) return false;

        return true;
    }

    function showErrorMessage(field) {
        if (errorFields[field]) return <p className={Style.errorDetails}>{errorFields[field]}</p>;
        return <></>;
    }

    async function submitForm() {
        if (!validateFormData()) return;
        setLoader(true);
        try {
            let response = await fetch(config.google_sheet_url, {
                method: "POST",
                body: new FormData(formRef.current),
            })
            if (response.ok) {
                setIsPopupVisible(true);
                document.body.style.overflow = "hidden";
                setName(""); setEmail(""); setContactNumber(""); setCountry(""); setPlan(""); setDescription("");
            }
            setLoader(false);

        } catch (error) {
            setLoader(false);
        }
    }

    function numberOnly(e) {
        const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
        if (allowedKeys.includes(e.key) || (e.key >= "0" && e.key <= "9")) return;
        e.preventDefault();
    }

    const closePopup = () => {
        setIsPopupVisible(false);
        document.body.style.overflow = "auto";
    };

    return (
        <section className={Style.getInTouch}>

            <div className={Style.formHeader}>
                <h1>Get in touch</h1>
                <p className={Style.description}>We'd love to hear from you. Please fill out the form to get in touch with us.</p>
            </div>

            <form className={Style.form} ref={formRef}>
                <div className={Style.formControl}>
                    <label htmlFor="name" name="name" className={Style.req}>Name</label>
                    <input id="name" type="text" name="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    {showErrorMessage("name")}
                </div>
                <div className={Style.formControl}>
                    <label htmlFor="email" name="email" className={Style.req}>Email</label>
                    <input id="email" type="text" name="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {showErrorMessage("email")}
                </div>
                <div className={Style.formControl}>
                    <label htmlFor="contact_number">Contact Number</label>
                    <input id="contact_number" type="tel" name="Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} onKeyDown={numberOnly} />
                </div>
                <div className={Style.formControl}>
                    <label htmlFor="country" className={Style.req}>Country</label>
                    <select name="Country" id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                        <option value="">Select Country</option>
                        {countries && countries.length > 0 && countries.map((country, index) => <option key={index} value={country}>{country}</option>)}
                    </select>
                    {showErrorMessage("country")}
                </div>
                <div className={Style.formControl}>
                    <label htmlFor="plan" className={Style.req}>Select Membership Plan</label>
                    <select name="Plan" id="plan" value={plan} onChange={(e) => setPlan(e.target.value)}>
                        <option value="">Select Plan</option>
                        <option value="Basic">Basic</option>
                        <option value="Premium">Premium</option>
                        <option value="Diamond">Diamond</option>
                    </select>
                    {showErrorMessage("plan")}
                </div>
                <div className={Style.formControl}>
                    <label htmlFor="description">Description</label>
                    <textarea id="description" name="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>

                <button type="submit" className={Style.submitBtn} disabled={loader} onClick={submitForm}>{loader ? "Sending..." : "Submit"}</button>
            </form>

            {isPopupVisible && (
                <>
                    <div className={Style.overlay} onClick={closePopup}></div>
                    <div className={Style.popup}>
                        <button className={Style.closeButton} onClick={closePopup}>&times;</button>
                        <div>
                            <svg className={Style.checkMark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className={Style.checkMarkCircle} cx="26" cy="26" r="25" fill="none" />
                                <path className={Style.checkMarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <p>Form submitted successfully!</p>
                        </div>
                    </div>
                </>
            )}

        </section>
    )
}

export default GetInTouch