import React from "react";
import { Logo } from '../../assets/images';
import { menu } from '../../data';
import Style from './style.module.scss';

function Footer() {
    return (
        <footer className={Style.footer}>
            <div className="container">
                <div className={Style.footerContent}>
                    <div className={Style.logo}><a href="/"><img src={Logo} alt="sellfromweb" title='sellfromweb' /></a></div>
                    {menu && menu.length > 0 &&
                        <ul className={Style.navLinks}>
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <a href={item.href}>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
                <div className={Style.copyRight}>
                    <p>© 2024 Sellfromweb. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
};

export default Footer