import React from 'react';
import Style from "./style.module.scss";
import { projects } from '../../data';

function Projects() {
    return (
        <section className={`container ${Style.projects}`}>
            <div className={Style.title}>
                <h2>Projects</h2>
            </div>
            <div className={Style.cards}>
                {projects && projects.length > 0 && projects.slice(0, 4).map((item, index) => (
                    <div className={Style.card} key={index}>
                        <div className={Style.image}>
                            <img src={item.img} alt={item.name} title={item.name} />
                        </div>
                        <p>{item.name}</p>
                        <span>{item.location}</span>
                    </div>
                ))}
            </div>
        </section>
    )
};

export default Projects;