import React, { useState, useRef } from "react";
import Style from './style.module.scss';

function Accordion({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={Style.accordion}>
            <a href={void(0)} className={`${Style.accordionHeader} ${isOpen ? Style.show : ""}`} onClick={toggleAccordion}>
                {title}
                <span className={Style.accordionIcon}><i /></span>
            </a>
            <div
                className={Style.accordionBody}
                style={{
                    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease",
                }}
                ref={contentRef}
            >
                <div className={Style.accordionContent}>{content}</div>
            </div>
        </div>
    )
}

export default Accordion;