import Footer from "./components/footer"
import Header from "./components/header"
import GetInTouch from "./pages/get_in_touch";
import Home from "./pages/home"
import Projects from "./pages/projects";

function Main() {

    let pathName = window.location.pathname || "";

    return <>
        <Header />
        {pathName === "/" && <Home />}
        {pathName === "/get-in-touch" && <GetInTouch />}
        {pathName === "/projects" && <Projects />}
        <Footer />
    </>
}

export default Main